var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.options
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c(
                "b-avatar",
                {
                  staticClass: "mr-1",
                  attrs: { rounded: "", size: "42", variant: "light-primary" }
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: _vm.options.icon, size: "20" }
                  })
                ],
                1
              ),
              _c("div", [
                _c("h4", { staticClass: "mb-0" }, [
                  _vm._v(" " + _vm._s(_vm.options.title) + " ")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.options.subtitle))])
              ])
            ],
            1
          ),
          _c(
            "app-collapse",
            {
              staticClass: "mt-2",
              attrs: { id: "faq-payment-qna", accordion: "", type: "margin" }
            },
            _vm._l(_vm.options.qandA, function(data, index) {
              return _c(
                "app-collapse-item",
                { key: index, attrs: { title: data.question } },
                [_vm._v(" " + _vm._s(data.ans) + " ")]
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }